/* .App {
  text-align: center;
} */

.App-header {
  background-color: #263553;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.definition {
  margin-top: -30vh;
  padding: 30px;
}

p {
  line-height: 150%;
}

.accent {
  color: #869ece;
}

b {
  background-color: #354668;
  color: #bdc1c6;
  padding: 3px 6px;
  border-radius: 3px;
}

.contact {
  position: fixed;
  left: 15px;
  bottom: 10px;
  width: 100%;
}

a {
  font-size: 14px;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}